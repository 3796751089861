<template>
  <div :class="['download', $store.state.lang !== 'zh' ? 'download_en' : '']">
    <!-- 左侧小图 -->
    <img class="download-icon" src="../../assets/images/words@2x.png" alt="">
    <!-- 左侧logo -->
    <img class="download-logo" src="../../assets/images/download-app-icon@2x.png" alt="">
    <!-- 右侧内容 -->
    <section class="download-section-1">
      <!-- 上侧内容 -->
      <div class="sec-1-title">
        <div class="sec-1-title-title">{{ $t('download.msg_1') }}</div>
        <div class="sec-1-title-word">
          <span class="sec-1-title-word-icon-left">&lt;</span>
          {{ $t('download.msg_2') }}
          <span class="sec-1-title-word-icon-right">&gt;</span>
        </div>
      </div>
      <!-- 文字 -->
      <div class="sec-1-desc">
        {{ $t('download.msg_3') }}
      </div>
      <!-- 下载连接 -->
      <div class="sec-1-download">
        <img @click="download('ios')" class="sec-1-download-img-1" src="../../assets/images/download-ios@2x.png" alt="">
        <img @click="download('android')" class="sec-1-download-img-2" src="../../assets/images/download-android@2x.png" alt="">
        <img class="sec-1-download-img-3" src="../../assets/images/download-app-downlaod@2x.png" alt="">
      </div>
      <!-- 标题 -->
      <p class="sec-1-title-2">//  {{ $t('download.msg_4') }}</p>
      <!-- 描述 -->
      <div class="sec-1-desc-1 sec-1-desc">
        <p class="desc-1">{{ $t('download.msg_5') }}</p>
        <p class="desc-2">{{ $t('download.msg_6') }}</p>
        <p class="desc-3">{{ $t('download.msg_7') }}：iPhone 11 Pro max、iPhone 11 Pro、iPhone 11、iPhone SE 2020、iPhone XS max、iPhone XS、iPhone XR、iPhone X、iPhone 8 Plus、iPhone 8。</p>
      </div>
      <div class="sec-1-desc-2 sec-1-desc">
        <p class="desc-1">{{ $t('download.msg_8') }}</p>
        <p class="desc-2">{{ $t('download.msg_9') }}</p>
        <p class="desc-3">{{ $t('download.msg_10') }}：HUAWEI P40 Pro、HUAWEI P40、HUAWEI P30 Pro、HUAWEI P20 Pro、HUAWEI P20、HUAWEI Mate 30 Pro、HUAWEI Mate 30 、Mate 20 Pro、Mate 10 Pro、HONOR 30 Pro、HONOR 20、SAMSUNG Galaxy S20+、Galaxy S20、Galaxy S10、Galaxy S9+、Galaxy S8、SAMSUNG Galaxy Note 9、Mi 10 Ultra、Mi 10 Pro、Mi 10、Mi 9、 Redmi K30 Pro、Vivo X30 Pro、Vivo X30 、Vivo X27、OPPO RENO 3 Pro、OnePlus 8、OnePlus 7 Pro、Pixel 4XL、Pixel 3XL、Pixel 2、LG V40。</p>
      </div>
      <!-- 末尾文字 -->
      <div class="sec-1-tips">
        *{{ $t('download.msg_11') }}
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    download (type) {
      if (type === 'ios') {
        window.location.href = 'https://apps.apple.com/us/app/botify清洁/id1518002664'
      } else {
         window.location.href = 'https://dl.botifyrobot.com/app/app_file/%E6%B1%87%E6%99%BA%E4%B8%87%E7%89%A9%E6%89%8B%E6%9C%BA%E5%AE%A2%E6%88%B7%E7%AB%AF%E4%B8%8B%E8%BD%BD.html'
      }
    },
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {

  .download_en {
    .download-section-1 {
      .sec-1-title {
        display: block!important;
        height: auto!important;;

        .sec-1-title-word {
          padding-top: .4rem!important;
          height: .4rem!important;
          line-height: .4rem!important;
          padding-left: 0!important;
        }
      }
    }
  }

  .download {
    background-color: #686B6C;
    min-height: calc(100vh - 4.8rem - 0.8rem);
    position: relative;

    .download-icon {
      width: .98rem;
      height: 1.16rem;
      position: absolute;
      top: .15rem;
      left: 1.51rem;
    }

    .download-logo {
      width: 1.8rem;
      position: absolute;
      top: 1.71rem;
      left: 4.25rem;
    }

    .download-section-1 {
      width: 9.57rem;
      margin-left: 6.7rem;
      padding-top: 1.71rem;

      // 上侧内容
      .sec-1-title {
        height: 1rem;
        color: #fff;
        display: flex;
        flex-direction: row;

        .sec-1-title-title {
          font-size: 1rem;
          line-height: .92rem;
          font-family: 'SourceHanSansCNRegular';
        }

        .sec-1-title-word {
          height: .92rem;
          line-height: .92rem;
          padding-left: 1.26rem;
          font-size: .28rem;
          font-family: 'SourceHanSansCNRegular';

          .sec-1-title-word-icon-left {
            color: #006EA5;
            padding-right: .05rem;
          }
          .sec-1-title-word-icon-right {
            color: #006EA5;
            padding-left: .05rem;
          }
        }
      }

      // 文字
      .sec-1-desc {
        padding-top: .3rem;
        font-size: .16rem;
        color: #C0C2C4;
        font-family: 'SourceHanSansCNRegular';
        line-height: .32rem;
      }

      // 下载连接
      .sec-1-download {
        width: 9.57rem;
        height: 1.2rem;
        padding-bottom: 1rem;
        padding-top: .36rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .sec-1-download-img-1 {
          width: 1.2rem;
          height: .4rem;
          cursor: pointer;
        }

        .sec-1-download-img-2 {
          width: 1.2rem;
          height: .4rem;
          margin-left: 1.1rem;
          cursor: pointer;
        }

        .sec-1-download-img-3 {
          width: 1.2rem;
          margin-left: 1.1rem;
        }
      }

      // 标题
      .sec-1-title-2 {
        font-size: .28rem;
        color: #fff;
        font-family: 'SourceHanSansCNRegular';
      }

      // 描述
      .sec-1-desc {
        font-size: .16rem;
        color: #C0C2C4;
        line-height: .4rem;
        font-family: 'SourceHanSansCNRegular';

        .desc-1 {
          font-family: 'SourceHanSansCNBold';
        }
      }

      // 末尾文字
      .sec-1-tips {
        color: #006B9C;
        line-height: .4rem;
        font-size: .16rem;
        padding-top: .4rem;
        padding-bottom: 1.4rem;
      }
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .download {
    background-color: #686B6C;
    position: relative;
    padding-top: .81rem;

    .download-icon {
      width: .98rem;
      height: 1.16rem;
      top: .12rem;
      left: .4rem;
      position: absolute;
    }

    .download-logo {
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 auto .32rem auto;
      display: block;
    }

    .download-section-1 {
      padding: 0 .4rem;

      // 上侧内容
      .sec-1-title {
        color: #fff;

        .sec-1-title-title {
          font-size: .72rem;
          text-align: center;
          font-family: 'SourceHanSansCNRegular';
        }

        .sec-1-title-word {
          padding-top: .47rem;
          font-size: .28rem;
          text-align: center;
          font-family: 'SourceHanSansCNRegular';

          .sec-1-title-word-icon-left {
            color: #006EA5;
            padding-right: .05rem;
          }
          .sec-1-title-word-icon-right {
            color: #006EA5;
            padding-left: .05rem;
          }
        }
      }

      // 文字
      .sec-1-desc {
        padding-top: .46rem;
        font-size: .22rem;
        color: #C0C2C4;
        font-family: 'SourceHanSansCNRegular';
        line-height: .48rem;
      }

      // 下载连接
      .sec-1-download {
        width: 100%;
        height: 1.2rem;
        padding-bottom: 1rem;
        padding-top: .36rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .sec-1-download-img-1 {
          width: 1.2rem;
          height: .4rem;
          cursor: pointer;
        }

        .sec-1-download-img-2 {
          width: 1.2rem;
          height: .4rem;
          cursor: pointer;
        }

        .sec-1-download-img-3 {
          width: 1.2rem;
        }
      }

      // 标题
      .sec-1-title-2 {
        font-size: .28rem;
        color: #fff;
        text-align: center;
        font-family: 'SourceHanSansCNRegular';
      }

      // 描述
      .sec-1-desc {
        font-size: .22rem;
        color: #C0C2C4;
        line-height: .48rem;
        font-family: 'SourceHanSansCNRegular';

        .desc-1 {
          text-align: center;
          font-family: 'SourceHanSansCNBold';
        }

        .desc-2 {
          text-align: center;
        }
      }

      // 末尾文字
      .sec-1-tips {
        color: #006B9C;
        font-size: .22rem;
        padding-top: .4rem;
        padding-bottom: 1.4rem;
        line-height: .48rem;
        text-align: center;
      }
    }
  }
}
</style>
